<template>
    <div>
        <UButton icon="i-heroicons-bars-3-20-solid" @click="isOpen = true" variant="ghost" color="gray" />

        <UModal v-model="isOpen">
            <UCard>
                <template #header>
                    <div class="flex items-center justify-between">
                        <p class="font-bold text-lg tracking-tight">NomadRx</p>
                        <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1"
                            @click="isOpen = false" />
                    </div>
                </template>
                <div class="">
                    <NuxtLink v-for="item in navigation" :key="item.name" :to="item.href" @click.prevent="toggleModal"
                        class="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900">
                        {{ item.name }}
                    </NuxtLink>
                </div>
                <UDivider class="my-5" />
                <div class="space-y-2">
                    <UButton to="/onboard" block size="lg">Create account</UButton>
                    <UButton to="/login" block size="lg" variant="soft" color="indigo">Existing client? Login</UButton>
                </div>
            </UCard>
        </UModal>
    </div>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const isOpen = ref(false)

const navigation = [
    { name: 'For Patients', href: '/' },
    { name: 'For Providers', href: '/for-providers' },
    { name: 'Blog', href: '/blog' },
]

function toggleModal() {
    isOpen.value = !isOpen.value
}
</script>
