<template>
  <div class="min-h-full flex">
    <NavigationTheMobileDrawer :show="navigationStore.sidebarOpen" />
    <div class="flex flex-col w-0 flex-1">
      <NavigationTheHeader />
      <main>
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  CalendarIcon,
  CheckCircleIcon,
  LockOpenIcon,
  PencilIcon,
  MagnifyingGlassIcon,
  TagIcon,
  UserCircleIcon as UserCircleIconSolid,
} from "@heroicons/vue/24/solid/esm/index.js";

const navigationStore = useNavigationStore();

const sidebarOpen = ref(false);
</script>