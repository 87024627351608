<template>
  <header class="sticky top-0 z-10">
    <nav aria-label="Top">
      <div class="bg-white shadow-sm">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="h-16 flex items-center justify-between" v-if="$device.isMobile">
            <!-- Left Bar Icons -->
            <div class="flex-1">
              <NavigationMobileMenu />
            </div>

            <!-- Center Logo -->
            <NuxtLink to="/" class="flex lg:hidden">
              <span class="sr-only">NomadRx</span>
              <div class="text-2xl font-bold mr-1 tracking-tight">
                Nomad<span class="text-gray-400">Rx</span>
              </div>
            </NuxtLink>
            <!-- Right Bar Icons -->
            <div class="flex flex-1 items-center justify-end">
              <div class="flex items-center lg:ml-8">
                <div class="flex space-x-8">
                  <div class="flex flex-1 items-center">
                    <UButton v-if="userStateReady && isClient" label="Account" :to="buttonLink" variant="ghost"
                      color="primary" />
                    <UButton v-else-if="userStateReady && isPartner" label="Dashboard" :to="buttonLink" variant="ghost"
                      color="primary" />
                    <UButton v-else label="Login" :to="buttonLink" variant="soft" color="primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="h-16 flex items-center justify-between" v-else>
            <div class="lg:flex-1 lg:flex lg:items-center">
              <NuxtLink to="/" class="flex">
                <span class="sr-only">NomadRx</span>
                <div class="text-2xl font-bold mr-1 tracking-tight">
                  Nomad<span class="text-gray-400">Rx</span>
                </div>
              </NuxtLink>
            </div>
            <div class="hidden lg:flex lg:gap-x-12">
              <NuxtLink to="/" class="text-sm font-semibold leading-6 text-gray-900"
                :class="{ 'highlighted': $route.path === '/' }">
                {{ t('forPatients') }}
                <span aria-hidden="true">
                </span>
              </NuxtLink>
              <NuxtLink to="/for-providers" class="text-sm font-semibold leading-6 text-gray-900"
                :class="{ 'highlighted': $route.path === '/for-providers' }">
                {{ t('forProviders') }}
              </NuxtLink>
            </div>


            <div class="flex flex-1 items-center justify-end">
              <div class="flex items-center mx-0 space-x-2">
                <!-- <UButton square icon="i-heroicons-globe-alt" @click="openLocaleModal" color="gray" variant="soft" /> -->
                <div class="flex flex-grow-0 ">
                  <GlobalLocaleDropdownUpdateButton />
                  <!-- <UDropdown :items="localeOptions" :popper="{ placement: 'bottom-end' }"
                    :ui="{ width: 'w-fit min-w-24' }">
                    <UButton square icon="i-heroicons-globe-alt" color="gray" variant="soft" />
                  </UDropdown> -->
                </div>
                <UButton v-if="!isAuthenticated" :label="t('signUp')" variant="soft" to="/onboard" size="xs" />
                <UButton :to="buttonLink" icon="i-mdi-arrow-right" size="xs" color="primary" variant="solid"
                  :label="buttonLabel" trailing />
                <!-- <UButton v-if="hasAccountAccess" label="Account" to="/account" size="xs" /> -->
              </div>


              <!-- <div class="flex items-center ">
                <div class="flex space-x-8">
                  <div class="
                        md:flex
                        md:flex-1
                        md:items-center
                        md:justify-end
                        md:space-x-6
                        truncate
                      ">
                    <UButton :to="buttonLink" class="font-medium min-w-32 justify-center" icon="i-mdi-arrow-right"
                      size="sm" color="primary" variant="solid" :label="buttonLabel" trailing />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { UserIcon } from "@heroicons/vue/24/solid/esm/index.js";
import { ArrowRightEndOnRectangleIcon, PresentationChartBarIcon, } from "@heroicons/vue/24/outline/esm/index.js";
import { GlobalModalUpdateLocale } from '#components'

const authStore = useAuthStore();
const { userStateReady, isPartner, isClient, hasAccountAccess, isAuthenticated } = storeToRefs(authStore);
const indexStore = useIndexStore();
const navigationStore = useNavigationStore();
const route = useRoute();
const modal = useModal()

// const { locale } = useI18n()
const { t, locale } = useI18n({
  useScope: 'local'
})

onMounted(() => {
  if (process.client) {
    locale.value = localStorage.getItem('locale') || 'en'
  }
})

// const localCookie = useCookie('locale')

function openLocaleModal() {
  console.log('openLocaleModal')
  modal.open(GlobalModalUpdateLocale)
}

const localeOptions = [
  [
    {
      label: 'English',
      click: () => {
        locale.value = "en"
        localStorage.setItem('locale', "en")
      }
    },
    {
      label: 'Español',
      click: () => {
        locale.value = "es"
        localStorage.setItem('locale', "es")
      }
    }
  ],
]

const localeOptions2 = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Español',
    value: 'es'
  }
]

const navigation = [
  // { name: "Pharmacy", to: "/", badge: null },
  { name: "Mental Health", to: "/mental-health", badge: { label: "New" } },
];

const buttonLink = computed(() => {
  if (userStateReady.value) {
    if (hasAccountAccess.value) {
      return "/account";
    } else if (isPartner.value) {
      return "/provider";
    } else {
      return "/login";
    }
  } else {
    return "/login";
  }
});


const buttonLabel = computed(() => {
  if (userStateReady.value) {
    if (hasAccountAccess.value) {
      return t('yourAccount');
    } else if (isPartner.value) {
      return t('yourClinic');
    } else {
      return t('signIn');
    }
  } else {
    return t('signIn');
  }
});


const badgeColor = computed(() => {
  const styles = {
    default: "bg-blue-100 text-blue-800",
    emerald: "bg-emerald-100 text-emerald-800",
    blue: "bg-blue-100 text-blue-800",
  };
  return styles[indexStore.themeColor] ?? styles.default;
});
</script>

<style>
.highlighted {
  color: rgb(74, 77, 213);
}
</style>

<i18n lang="json">{
  "en": {
    "forPatients": "For Patients",
    "forProviders": "For Providers",
    "yourAccount": "Account",
    "yourClinic": "Dashboard",
    "signIn": "Sign in",
    "signUp": "Sign up"
  },
  "es": {
    "forPatients": "Para Pacientes",
    "forProviders": "Para Médicos",
    "yourAccount": "Account",
    "yourClinic": "Dashboard",
    "signIn": "Iniciar",
    "signUp": "Regístrate"
  }
}</i18n>