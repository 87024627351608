<template>
  <TransitionRoot as="template">
    <Dialog as="div" class="relative z-40 lg:hidden" @close="navigationStore.closeSidebar()">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
        enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 flex z-40">
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0" leave-to="-translate-x-full">
          <DialogPanel class="
              relative
              flex-1 flex flex-col
              max-w-xs
              w-full
              pt-5
              pb-4
              bg-white
            ">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
              leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  " @click="navigationStore.closeSidebar()">
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <div class="text-2xl font-bold mr-1 tracking-tight">
                Nomad<span class="text-gray-400">Rx</span>
              </div>
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2">
                <div class="space-y-1">
                  <NuxtLink v-for="item in authStore.isAuthenticated
                    ? auth_navigation
                    : navigation" :key="item.name" @click="navigate(item)" :class="[
    $route.path == item.to
      ? 'bg-gray-100 text-gray-900'
      : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
    'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md',
  ]" :aria-current="item.current ? 'page' : undefined">
                    <component :is="item.icon" :class="[
                      item.current
                        ? 'text-gray-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6',
                    ]" aria-hidden="true" />
                    {{ item.name }}
                  </NuxtLink>
                </div>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ClockIcon,
  HomeIcon,
  UserCircleIcon as UserCircleIconOutline,
  ShoppingCartIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline/esm/index.js";
import {
  CalendarIcon,
  CheckCircleIcon,
  LockOpenIcon,
  PencilIcon,
  MagnifyingGlassIcon,
  ArrowLeftOnRectangleIcon,
  TagIcon,
  UserCircleIcon as UserCircleIconSolid,
} from "@heroicons/vue/24/solid/esm/index.js";

const navigationStore = useNavigationStore();
const authStore = useAuthStore();

const navigation = [
  { name: "Home", to: "/", icon: HomeIcon, current: true },
  // { name: "Search", to: "/search", icon: MagnifyingGlassIcon, current: false },
  // { name: "Cart", to: "/cart", icon: ShoppingCartIcon, current: false },
  {
    name: "Signin",
    to: "/signin",
    icon: ArrowLeftOnRectangleIcon,
    current: false,
  },
];
const auth_navigation = [
  { name: "Home", to: "/", icon: HomeIcon, current: true },
  {
    name: "Account",
    to: "/account",
    icon: UserCircleIconOutline,
    current: false,
  },
];

async function navigate(item) {
  const router = useRouter();
  const route = useRoute();
  // console.log("router: ", router);
  if (route.path == item.to) return navigationStore.closeSidebar();
  // router.push({
  //   path: item.to,
  //   query: {},
  // });
  await navigateTo(item.to);
}
</script>
